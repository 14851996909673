<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="35%" :before-close="handleClose">
      <template slot="title">
        <div class="plugin-modal-header">
          <div class="plugin-info">
            <div class="plugin-img">
              <img :src="SearchIcon" alt="" />
            </div>
            <div class="plugin-name">
              <p>Search Engine</p>
            </div>
          </div>
        </div>
      </template>
      <div class="plugin-modal-body">
        <div>
          <p>You are about to enable Search Engine.</p>
        </div>
        <div>
          <div>
            <p>Select Search Engine</p>
            <el-select v-model="tempConfig.name" placeholder="Select Search Engine">
              <el-option label="Google" value="google"></el-option>
              <el-option label="Bing" value="bing"></el-option>
            </el-select>
          </div>
          <div>
            <!-- Whitelist websites -->
            <div style="display: flex; align-items: center">
              <p>Whitelist websites</p>
              <!-- tooltip -->
              <el-tooltip
                effect="dark"
                content="Websites that you want the search engine to be searched on, leave it empty to search on all websites"
                placement="top"
                style="margin-left: 5px"
              >
                <i class="el-icon-info"></i>
              </el-tooltip>
            </div>
            <div
              style="
                padding: 10px;
                border: 1px solid #dedede;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                box-sizing: border-box;
                border-radius: 5px;
              "
            >
              <el-tag
                :key="website"
                v-for="website in tempConfig.whiteList"
                closable
                :disable-transitions="false"
                @close="handleCloseWebsite(website)"
                style="margin-right: 10px"
              >
                {{ website }}
              </el-tag>
              <el-input
                class="input-new-tag"
                v-if="inputVisible"
                v-model="inputValue"
                ref="saveWebsiteInput"
                size="mini"
                @keyup.enter.native="handleInputConfirm"
              >
              </el-input>
              <el-button v-else class="button-new-tag" size="small" @click="showInput"
                >+ New website</el-button
              >
            </div>
          </div>
        </div>
      </div>

      <template slot="footer">
        <div>
          <el-button type="plain" @click="handleClose">Cancel</el-button>
          <el-button type="primary" @click="handleConfirm">Confirm</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import SearchIcon from "./assets/icons/search-engine.png";

export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    selectedPlugin: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      test: false,
      tempConfig: {
        name: "",
        whiteList: [],
      },
      SearchIcon,
      inputVisible: false,
      inputValue: "",
    };
  },
  methods: {
    handleClose() {
      this.$emit("updateDialogVisible", false);
    },
    handleConfirm() {
      this.$confirm("Do you want to save the current settings ?", "Warning", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.$emit("updatePluginConfig", this.tempConfig);
        })
        .catch(() => {});
    },
    handleCloseWebsite(website) {
      this.tempConfig.whiteList.splice(this.tempConfig.whiteList.indexOf(website), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveWebsiteInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      const validate = this.validateWebsiteUrl(inputValue);
      if (inputValue && validate) {
        this.tempConfig.whiteList.push(inputValue);
        this.inputVisible = false;
        this.inputValue = "";
      }
    },

    validateWebsiteUrl(url) {
      if (this.tempConfig.whiteList.includes(url)) {
        this.$message.error("This website is already in the whitelist");
        return false;
      }

      const regex = new RegExp("^(http|https)://", "i");
      if (!regex.test(url)) {
        this.$message.error("Please enter a valid URL");
        return false;
      }

      return regex.test(url);
    },
  },
  created() {
    if (this.selectedPlugin) {
      this.tempConfig = _.cloneDeep(this.selectedPlugin.config);
    }
  },
};
</script>

<style lang="scss">
@import "./assets/css/modal.scss";
</style>
